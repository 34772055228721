
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
@font-face {
  font-family:	"Futura Md BT";
  src: url('https://webapi.entab.info/api/image/PCSD/public/font/futura.otf');
} 
h1,h2,h3,h4,h5,h6{ 
  font-family:	"Futura Md BT";
}
.lazyload-wrapper {
  display: unset;
}
.campuslogo {
  position: fixed;
  top: 45%; 
  right: 0;
  z-index: 2;
}
.nav-link {
  padding: 10px 10px;
  font-family: 'Futura Md BT';
  font-size: 16px;
  font-weight: normal;
  color: #fff;
  margin-right: 10px;
  margin-left: 10px;
  text-transform: uppercase;
}
li.nav-item:first-child {
  background: no-repeat;
}
.navbar-expand-lg .navbar-nav {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 0;
  width: 100%;
}
.nav-link img {
  padding-left: 5px;
}
li.nav-item {
  position: relative;
}
li.nav-item{ 
  background: url('https://webapi.entab.info/api/image/PCSD/public/Images/line.png') no-repeat;
}
li.nav-item:first-child:before{
background:none;

}
.nav-link:focus, .nav-link:hover{
  color: #fff;
}
a{
    text-decoration: none;
}.headermid {
    margin:-45px auto 10px auto;
    display: block;
    text-align: center;
}
.header-nav {
    background: linear-gradient(180deg, #D6FFCE -115.4%, #4D9F3D 79.74%);
    width: 63%;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    border-radius: 3px;
}
.homeslider {
    position: relative;
    margin-top: -30px;
}
p{
    font-family: "Manrope", sans-serif;
}
.homeslidergif {
    position: absolute;
    bottom: 50px;
    width: 100%;
    margin: 0 auto;
    display: block;
    text-align: center;
}
.homeslidergif img.img-fluid {
    width: 14%;
}
.headertop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.mainheader {
  background: linear-gradient(180deg, #FFFFFF 0%, #D3D3D3 114.94%);
}
.speaker {
  width: 90px;
  padding-left: 30px;
  padding-right: 20px;
}
.highlights {
    width: 46%;
    padding-right: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: linear-gradient(0deg, #D6FFCE, #D6FFCE);
    margin: 0 auto;
    text-align: center; 
}
.highlights marquee {
  position: relative;
  z-index: 2;
}
  .highlights p a {color: #333;line-height: 30px;  
    font-size: 18px;}
    .highlights p {
      display: inline-block;
        color: #333;
        margin-bottom: 0;
        padding: 5px;
    }
    .highlight-tab .space-even a img {
      width: 16px !important; 
      
      } 
      
      .highlight-tab {
        width: 270px;
    
      }
      
      .highlight-tab a {
        padding: 5px 15px;
      }
      .highlight-blk{
        padding-left: 20px;
        position: relative;
        color: #fff;
        margin-bottom: 0;
        text-align: center;
        overflow: hidden;
        background: linear-gradient(90deg, #D6FFCE -26.32%, #4D9F3D 88.77%);
      }
      .highlight-blk p{
        margin-bottom: 0;
      }
      .highlight-blk a img {
      width: 33px;
      }
      p.d-flex.space-even.highlight-blk:before {
        position: absolute;
        content: "";
        width: 70px;
        height: 70px;
        background: #f9f9f9;
        left: -50px;
        top: 0px;
        transform: rotate(56deg);
      }
      .topheaderdesc img {
        width: 30px;
      }
      .topheaderdesc{
        align-items: center;  
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 55px;
        width:600px;
      }
      .topheaderleftbg {
        position: relative;
    }
      .topheaderleft { 
        width: 30%; 
    } 
    .shape {
      width: 600px;
      height: 50px;
  }
      .topheaderleft a {
        font-size: 14px;
        line-height: 18px;
        padding: 0px 5px;
        color: #454545;
    }
    .topheaderleft p {
      margin-right: 25px;
      margin-bottom: 0;
  }
    .topheaderleft span {
        display: block;
        line-height: 0;
    }
  .midsection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    position: absolute;
    z-index: 1;
    bottom: -100px;
}

.midsectiondiv {
    width: 28%;
}

.midsectionboximg img {
    width: 80px;
}
.midsectiondiv.scd .midsectionboximg {
    display: flex;
    justify-content: end;
}
.midsectionboxpara li {
    font-size: 18px;
    line-height: 30px;
    color: #fff;
}
.midsectiondiv.scd .midsectionboxdesc {
    justify-content: end;
}
.midsectionbox {
    display: flex;
        padding-top: 30px;
        padding-bottom: 30px;
    flex-direction: column;
}
.mainhomeslider {
    position: relative;
}

.midsectiondiv.scd .midsectionboxpara {
    padding-left: 50px;
}
.midsectionboxdesc {
    display: flex;
    padding-bottom: 40px;
    justify-content: center;
    align-items: center;
}
.midsectiondiv.fst{
    padding: 50px 50px 50px 0px;
    background:url('https://webapi.entab.info/api/image/PCSD/public/Images/midsection1.png') no-repeat;
    margin-top: -90px;
    background-size: 100% 100%;

}
.midsectiondiv.fst p {
    color: #fff;
    font-size: 18px;
    line-height: 30px;
}
.midsectiondiv.scd{
    margin-top: -90px;

    background:url('https://webapi.entab.info/api/image/PCSD/public/Images/midsection2.png') no-repeat;
    background-size: 100% 100%; 
}
.midsectionboxtitle h6 {    color: #ffffff59;
    padding-right: 20px;width: 11px;word-wrap: break-word;font-family: monospace; /* this is just for good looks */white-space: pre-wrap;font-weight: bold;font-size: 20px;}

.midsectionboxtitle {
    padding: 0px 18px;
}
.homeabout {
    position: relative; 
    background:url('https://webapi.entab.info/api/image/PCSD/public/Images/aboutbg.gif') no-repeat;
    width: 100%;
    background-size: cover;
    padding-top: 180px;
    margin-top:  0px;
    padding-bottom: 100px;
}
.homeabout:before{
    position: absolute;
    content: ' ';
    opacity: 0.9; 
    background: #fff;
    width: 100%;
    height: 100%;
    top: 0;
}
.homeabout .container-fluid {
    position: relative;
}
 .fulwidth {
    width: calc(90% - 100px);
}
.titleheading h1 span {
    color: #4D9F3D;
    text-transform: uppercase;
    font-size: 12px;
    display: block;
    letter-spacing: 0;
    line-height: 12px;
}
.titleheading h1 {
    font-size: 35px;
    font-weight: bold;
    letter-spacing: 8px;
    line-height: 45px;
}.homeaboutdesc p {
    font-size: 18px;
    line-height: 30px;
}
.mainbtn {
    margin-bottom: 20px;
    margin-top: 20px;
}
.schoolsong h6 {
    font-size: 22px;
    line-height: 32px;
    color: #666;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 20px;
}
audio {
    width: 100%;
}
.schoolsong {
    width: 75%;
    margin-top: 65px;
}
.titleheading {
    margin-bottom: 30px;
    margin-top: 30px;
}
.homeaboutdesc {
    margin-bottom: 30px;
}
.homenews h1{
color: #000000a8;
}
.homenews{
    background:url('https://webapi.entab.info/api/image/PCSD/public/Images/eventbg.png') no-repeat;
 
    padding: 60px 0px 60px 0px;
    background-size: cover;
} 
.topperandbirth {
  padding: 60px 0px 60px 0px;
}
.newsbg {
    background: url('https://webapi.entab.info/api/image/PCSD/public/Images/newsbg.png') no-repeat 100% 100%;
    margin-bottom: 0;
    background-size: 100% 100%;
    padding: 20px 0px 80px 30px; 
    padding: 50px 50px;
    border-radius: 4px;

} 
.newsdesc p {
    font-size: 18px;
    font-weight: 400;
    line-height: 30px; 
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
 
  .newsbox {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .newsbox a i {
    background: linear-gradient(148.57deg, #5B9AC9 5.89%, #175481 91.14%);
    border-radius: 12px 12px 12px 12px;
    color: #fff;
    font-size: 20px;
    padding: 5px 7px;
    line-height: 30px;
  }
  .newsbox a i::before {
    transform: rotate(320deg);
    line-height: 25px;
  }
  .newsdesc h6 {
    font-size: 18px;
    line-height: 30px;
    font-weight: 600;
    padding-right: 20px;
    
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 0;
}
  ul.newsboxlist {
    padding-left: 20px;
  }
  .maintitle p {
    margin-left: -48px;
    -webkit-text-stroke: 1px #666;
    color: #0000;
    font-size: 35px;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 0;
}
.maintitle>div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.maintitle h1 {
    font-size: 25px;
    line-height: 35px;
}

.homenewsboxdesc {
    padding: 15px 0px;
  }
  
  .homenewsboxtitle {
    z-index: 9;
    position: relative;
    border-radius: 7px;
    margin-top: 6px;
  }
  .homenewsboxtitle p {
    color: #fff;
    background: linear-gradient(180deg, #5B9AC9 0%, #175481 34.63%);
    text-transform: uppercase;
    font-weight: 400;
    font-size: 20px !important;
    padding: 5px 5px;
    text-align: center;
    margin-bottom: 0;
    line-height: 30px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    border-radius: 8px;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .homenewsboximg img {
    border-radius: 5px;
    height: 250px;
    width: 100% !important;
  }
  .homenewsboxdesc p { 
    color: #444;
    font-size: 18px;
    padding: 0px 10px;
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 30px;
    margin-top: 10px;
  }
  .homenewsboxbottom {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .homenewsbox {
    padding: 10px;
    background: #fff;
    border-radius: 5px;
    
  }
  .homenewsbox {
    margin: 0px 15px 0px 0px;
  }
  .homenewsboxdesc span i {
    color: #1a4872;
  }
  .newsbg marquee {
    height: 480px;
}
.homenewsboxdesc span img {
    display: inline-block;
}
.eventbg {
    background: url('https://webapi.entab.info/api/image/PCSD/public/Images/eventsbg.png') no-repeat 100% 100%;
    margin-bottom: 0;
    background-size: 100% 100%;
    padding: 20px 0px 80px 30px;
}
.maintitle a {
    align-items: center;
    background: linear-gradient(180deg, #D6FFCE -115.4%, #4d9f3d 79.74%);
  
    border-radius:15px;
    display: flex;
    float: right;
    font-size: 20px;
    height: 45px;
    justify-content: center;
    position: relative;
    width: 45px;
    z-index: 1;
    top: -70px;
    color: #fff;
}
.eventgif img {
    width: 35px;
}
.eventgif h1 {
    margin-left: -54px;
    
    color: #000000a8;
}
.clr {
    clear: both;
}
.topperbtn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
 
.topperbox {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  margin-top: 30px;
}

.topperdiv {
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.topperimages img {
    border-radius: 60px;
    width: 120px !important;
    height: 120px;
    margin-bottom: 20px;
    background: linear-gradient(180deg, #5B9AC9 0%, #175481 34.63%);
    padding: 5px;
}

.topperimages {
  position: relative;
}

.topperdesc p {
    text-align: center;
    color: #4ea03e;
    margin-bottom: 5px;
    font-weight: bold;
    margin-top: 5px;line-height: 24px !important;
} 
p.name {
    color: #333;
    font-size: 18px;
}
.tooperpicicn {
  margin-top: -27px;
  width: 125px;
}
.topperdesc p span {
    display: block;
    color: #333!important;
    font-weight: normal;
}
button {
    border: none;
    background: linear-gradient(178deg, #2869a5, #103252);
    color: #fff;
    padding: 10px 15px;
    border-radius: 15px;
  }
  .topperribbon {
    text-align: center;
    margin: 10px 0px 10px 0px;
}
.birthdaysec{
    margin-top: 30px;
    background: url('https://webapi.entab.info/api/image/PCSD/public/Images/birthdaybg.png') no-repeat 100% 100%;
    background-size: cover;
    height: 620px;
    padding: 50px;
    position: relative;
    background: #e3e3e3;
    border-radius: 4px;
    box-shadow: 0px 3px 6px 1px #ccc;
}
 
.birthdaysec:after{
    content: ' '; 
    right: -60px;
    bottom: 60px; 
    width: 100%;
    height: 100%;
    position: absolute;
    background: url('https://webapi.entab.info/api/image/PCSD/public/Images/ballonright.png') no-repeat  right bottom;
}
.birthdaysec:before{
    content: ' ';
    width: 100%;
    height: 100%;
    position: absolute;
    background: url('https://webapi.entab.info/api/image/PCSD/public/Images/ballonleft.png') no-repeat top left;
    left: -60px;
    top: -60px;
}
.birthdaysec .topperribbon {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
}
.topperribbonbottom {
    position: absolute;
    bottom: 30px;
    left: 0;
}
.toppersecbg {
    margin-left: 100px;
    position: relative;
}
.toppersecbg::before{
content: ' ';
position: absolute;
width: 100%;
height: 100%;
background: url('https://webapi.entab.info/api/image/PCSD/public/Images/topperbg1.png') no-repeat;
top: 0;
left: 120px;
opacity: 0.1;
}
.toppersecbg::after{
  content: ' ';
position: absolute;
width: 100%;
height: 100%;
background: url('https://webapi.entab.info/api/image/PCSD/public/Images/topperbg2.png') no-repeat right top;
 
    top: 0;
    right: 180px;
    opacity: 0.1;
}
.birthdayboximg img {
    border-radius: 60px;
    width: 90px !important;
    height: 90px;
    margin-bottom: 20px;
    background: linear-gradient(180deg, #5B9AC9 0%, #175481 34.63%);
    padding: 5px;
}
.birthdayboxdesc {
  width: 70%;
}
.birthdaybox {
    display: flex; 
    align-items: center;
}

.birthdayboximg {
    margin-right: 20px;
    width: 90px ;
    height: 90px;
}

.birthdayboxdesc h5 {
    color: #333;
    font-size: 18px; 
    margin-bottom: 5px;
    font-weight: bold;
    margin-top: 5px;
}
.birthdayboxdesc p {
    display: block;
    color: #333!important;
    font-weight: normal;
    font-size: 18px;
}
.birthdayboxdesc p span {
    color: #4ea03e;
    display: block;
    font-weight: bold;
}
.birthdaysec marquee {
padding-top: 50px;
    margin-bottom: 50px;
    margin-top: 40px;
    padding-left: 20px;
    position: relative;
    z-index: 1;
    height: 400px;
}
.homemsgsec  {
  padding: 0px 0px 60px 0px;
  background:  url('https://webapi.entab.info/api/image/PCSD/public/Images/msgbg.png') no-repeat;
  background-size: cover;
}
.homemsgboxdesc {
  border: 1px solid #4d9f3d;
  background: #fff;
  padding: 50px 50px;
  border-radius: 4px; 
  width: 90%;
  position: relative;
  margin: 0 auto;
}
.homemsgboxdesc>div {
  font-size: 18px;
line-height: 32px;
text-align: center;
display: -webkit-box;
-webkit-box-orient: vertical;
-webkit-line-clamp: 5;
overflow: hidden; 
    max-height: 266px;
}
.homemsgboxdesc:before{
  content: ' ';
  position: absolute;
  width: 100%;
  height: 100%;
  background:url('https://webapi.entab.info/api/image/PCSD/public/Images/quotes.png') no-repeat;
  left: -5px;
  top: -20px;
}
.homemsgsec .mainbtn {
  margin: 30px auto 30px auto;
  display: block;
  text-align: center;
}
.homemsgboxdesc:after{
  content: ' ';
  position: absolute;
  width: 100%;
  height: 100%;
  background:url('https://webapi.entab.info/api/image/PCSD/public/Images/quotes1.png') no-repeat right bottom;
  right: -5px;
  bottom: -20px;
}
.homemsgboxdesc p {
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
}
.homemsgboxtop {
  margin: 0 auto;
  display: block;
  text-align: center;
}
.homemsgboximg img {
  border-radius: 10px;
  background: #fff;
  width: 210px;
}
.homemsgboxname {
  background: linear-gradient(180deg, #D6FFCE -116.3%, #4D9F3D 84.21%);
  width:300px;
  margin: 0 auto;
  border-radius: 15px 15px 0px 0px;
  padding: 10px;
}
.homemsgbox {
  position: relative;
}
.homemsgbox:after{
/*   content: ' ';
  position: absolute;
  width: 20%;
  height: 100%;
  background:url('https://webapi.entab.info/api/image/PCSD/public/Images/msgretangle.png') no-repeat;
  transform: translate(-50%, -50%);
  top: 84%;
  left: 50%;
  z-index: -1;
  background-size: contain; */
}  
.homemsgbox:before {
  content: ' ';
  position: absolute;
  /* width: 20%; */
  /* height: 30%; */
/*   transform: translate(-50%, -50%);
  top: 103%;
  left: 50%;
  border-bottom: 0px solid #0000ff00;
  border-left: 50px solid #222b2208;
  border-right: 50px solid #00000000;
  border-top: 51px solid #ffffff; */
}
.homemsgboxname p span {
  display: block;
  font-weight: normal;
}

.homemsgboxname p {
  color: #fff;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 0;
  font-weight: 700;
}
.homegallery {
    padding:60px 0px 60px 0px;
    background:  url('https://webapi.entab.info/api/image/PCSD/public/Images/gallerybg.png') no-repeat;
    background-size: cover;
  }
  .homegallery .maintitle p {
    margin-left: -130px;
  }
  .homegallery  .eventgif img {
    width: 60px;
}
.galleryimg {
    margin-bottom: 5px; 
    padding: 10px;
    background-size: 100% 100%;
    height: 296px;
    margin-right: 10px;
  }
  .galleryimgbox img {
    min-height: 100%;
    object-fit: cover;
    background: #fff;
  }
  .gallerydesc {
    position: absolute;
    width: 95%;
    height: 93%;
    top: 0;
    left: 0;
    opacity: 0;
    transition: all 0.7s ease;
    background: linear-gradient(182deg, #0303038f, #1110109c);
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px;
  }
  .galleryimg:hover .gallerydesc {
    opacity: 1;
  }
  .galleryimgbox {
    position: relative;
    overflow: hidden;
    height: 250px;
    min-height: 250px;
  }
  
  .gallerydesc p {
    font-size: 16px;
    line-height: 30px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  
  .gallerydesc i {
    font-size: 35px;
  }
  
  .gallerydesc h6 {
    color: #fff;
    font-size: 22px;
    font-weight: bold;
    font-family: monospace;
    line-height: 32px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 0;
    margin-top: 20px;
  }
 .galleryimg:hover{
transform: scale(1.1);
transition: all 0.5s ease-in;
 } 
 
 .footer iframe {
  height: 240px;
  box-shadow: 0px 8px 8px -4px #00000040;
  border: 10px solid #ccc;
}
  .app-cont {
    width: 70%;
  }
   
  .footer .link{
    color: #000;
    padding: 5px 23px;
    border-radius: 8px;
    background: linear-gradient(173deg, #ffb800, #ef8f00);
    margin-top: 16px;
    width: max-content;
    max-width: 100%;
  }
  .copyright p {
    text-align: center;
    margin-bottom: 10px; 
    font-size: 12px;
    color: #333;
  }
  .footer {
    color: #fff;
    background:   url('https://webapi.entab.info/api/image/PCSD/public/Images/footerbg.png');
    background-size: 100% 100%;
    padding: 70px 0px 0px 0px;
    position: relative;
  }
  .footer:after{
    position: absolute;
    content:' ';
    width: 100%;
    height: 100%;
top:0;
background: linear-gradient(193.91deg, #D6FFCE -21.22%, #4D9F3D 89.72%);
z-index: -1;
  }
  .quick-link li {
    list-style: none;
    font-size: 16px;
    line-height: 35px;
  }
  .quick-link li a{
    color: #333;
  }
  .quick-link { 
    display: flex;
    flex-wrap: wrap;
  } 
  .cont-dtl p {
    font-size: 16px;
    line-height: 30px;
  }
  .cont-dtl p a{
    color: #333;
  }
  .quick-link ul {
    width: 50%;
    padding-left: 10px;
  }
  .cont-dtl h3 {
    padding-left: 10px;
    font-size: 18px;
    line-height: 30px;
    color: #333;
    padding-bottom: 10px;
    text-transform: uppercase;
  }
  .cont-dtl p a img {
    padding-right: 7px;
}
  
  .copyright p a {
    color: #fff;
  }
  
  .socialicn ul {
    display: flex;
    position: relative;
    justify-content: flex-end;
    margin-bottom: 0;
}
.socialicn ul:before {
  position: absolute;
  content: ' ';
  width: 74%;
  height: 1px;
  background: #fff;
  right: 170px;
  top: 20px;
}
.socialicn ul li {
    padding: 5px;
    list-style: none;
} 
.innerslide {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background: linear-gradient(360deg, #1c1b3b8f 100%, #1c1b3b91 100%, #3333338c 100%) , url('https://webapi.entab.info/api/image/PCSD/public/Images/innerslide.jpg') no-repeat center top;;
  background-size: cover;
  height: 255px;
  padding-bottom: 50px;
}
 
.innersec {
  min-height: 400px;
  padding: 60px 10px; 
  background: linear-gradient( #055a910f, #055a910f), url('https://webapi.entab.info/api/image/PCSD/public/Images/innerbg.png') repeat;
  position: relative;
}
.innersec p {
  line-height: 34px;
  font-size: 16px;
  color: #4e4e4e; 
}
.innersec h1 {
  font-size: 35px; 
  position: relative; 
  background:-webkit-linear-gradient(#bfd875, #006e27);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 45px;
  padding-bottom: 0px;
  margin-bottom: 45px;
  padding-left: 15px;
}
.innersec h1:before {
  background: -webkit-linear-gradient(#bfd875, #006e27);
  content: " ";
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0;
  width: 5px;
}
.innersec h5 {
  font-size: 20px;
  color: #75af57;
  line-height: 30px;
} 
.breadcrumb li:before {
  position: absolute;
  content: ' \F138 ';
  width: 100%;
  height: 100%;
  font-family: bootstrap-icons;
  left: -15px;
}
.breadcrumb li:first-child:before {
  content: "\f404";
  background: #006e27;
  width: 30px;
  border-radius: 20px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -25px;
  top: -3px;
  color: #eeda70;
}
.breadcrumb li a {
  color: #f9f9f9;
  position: relative;
}
.breadcrumb li {
  padding: 0px 15px 0px 15px;
  color: #fff;
  position: relative;
  margin-left: 7px;
  font-size: 14px;
}
ul.breadcrumb {
  margin-bottom: 0;
} 
.disclouser_list {
  display: flex;
  flex-wrap: wrap;
} 
.disclouser_list  a {
  width: calc(27% - 46px);
  margin-bottom: 20px;
  padding: 10px 5px;
  margin-right: 20px;
  background: #e3f0e1;
  display: flex; 
  box-shadow: 0px 0px 4px 1px #00000052;
  border-radius: 4px;
  align-items: center;
}

.disclouser_list  a img {
  width: 45px;
  margin-right: 15px;
}

.list li {
  margin-right: 22px;
  width: calc(49% - 20px ); 
}

.list {
  display: flex;
  flex-wrap: wrap;
}
.management {
  background: #fff;
  padding: 10px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 5px 0px #ccc;
  border-radius: 5px;
  border: 10px solid #fff;
}

.management img {
  margin: 0 auto;
  display: block;
  height: 180px;
  object-fit: contain;
  width: 100%;
}
.management h5 span {
  display: block;
}
.management h4 {
  font-family: 'Manrope';
  font-size: 17px;
  text-align: center;
  color: #eeda70;
  font-weight: bold;
  padding: 10px;
  margin-bottom: 0px;
}

.management h5 {
  font-family: 'Manrope';
  font-size: 14px;
  text-align: center;
  color: #fff;
}
.innersec ul li {
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  line-height: 33px;
  color: #333;
}
.management-detils { 
  background:#429342;
}
.maintable thead th {
  border: 1px solid #ccc;
  background: #35602d;
  color: #fff;
  vertical-align: middle;
}

.maintable tbody td {
  border: 1px solid #ccc;
}

.maintable tbody tr:nth-child(odd) {
  background: #52914624;
}

.maintable tbody tr:nth-child(even) {
  background: #fff;
}
.maintable .tablebg {
  background:#4a9745 !important;
  color: #fff;
}
.objm {
  border: 1px double #ccc;
  padding: 5px;
  border-radius: 4px;
}
 .uniformimg {
  float: right;
  width: 20%;
  border: 1px solid #0000004a;
  padding: 4px;
  border-radius: 4px;
  margin-left: 20px;
}
.rightimg {
  float: right;
  margin-bottom: 20px;
  margin-left: 20px;
}    
.leftimg {
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
}
.infra img {
  box-shadow: 1px 1px 8px 1px #ccc;
  margin-bottom: 10px;
  border-radius: 3px;
}
 
.infra h5 {
    margin-bottom: 20px;
    margin-top: 20px;
}
 .sportimg {
  width: 33%;
  margin-top: 20px;
}
.sdd-imgs img {
  width: 100%;
  height: 350px;
  object-fit: contain;
  background: #9edc9238;
  border: solid 15px #fff;
  margin-bottom: 20px;
  box-shadow: 0px 0px 9px 0px #ccc;
  border-radius: 3px;
}
.video-sdg{background: #9edc9238;position:relative;top:8px;padding: 10px 20px;width: max-content;border-radius:30px;margin-bottom:40px;}
.video-sdg a{display: flex;align-items: center;justify-content: center;}
.video-sdg p{background: none;font-size: 18px;margin-left: 20px;margin-bottom: 0px;}
.video-img{width:40px;} 
.navbar-nav ul.dropdown-menu {
  padding: 0;
  border-radius: 3px;
} 
.navbar-nav .dropdown-item.active, .navbar-nav .dropdown-item:active {
  background: #58a648;
}
.tcblock {
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
} .tc {
  width: 49%;
  margin-bottom: 30px;
} .tcblock iframe {
  width: 49%;
} .tc label {
  color: #000;
  width: 100%;
  margin-bottom: 0;
} .tc label p {
  margin-bottom: 0px;
} .tc .form-group {
  margin-bottom: 20px;
} .form-control:focus {
  box-shadow: none;
  border: 1px solid #ccc;
} a.viewdetailsbtn {
  color: #fff;
  font-size: 14px;
} .viewdetailsbtn {
  padding: 10px 15px;
  text-decoration: none;
  color: #fff;
  border-radius: 5px;
  margin-bottom: 20px;
  border: none;
  background:linear-gradient(45deg, #273c5b, #76bc69);
  transition: all 0.2s ease-in;
} .viewdetailsbtn:hover {
  background:linear-gradient(45deg, #5fac51, #4d9f3d);
}
.verticaltabs .nav-pills .nav-link.active {
  background: -webkit-linear-gradient(#bfd875, #006e27);
  color: #fff;
}
.nav-pills .nav-link {
    background: none;
    border: 0;
    border-radius: 0.25rem;
    background: #b5d7f9;
    margin: 0px 5px;
    padding: 2px 12px;
    color: #000000bd;
}
#sidebar a.active {
  color: #ff0000;
}
.nav-tabs li button {
  color: #4e4e4e;
}
.verticaltabs {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.verticaltabs .tab-content {
  padding: 0px 12px;
  width: 70%; 
  border-left: none;
  min-height: 422px;
  padding-top: 20px;
}

.verticaltabs .nav-pills {border: 1px solid #eeeeee;background-color: #eeeeee;width: 25%; }

.verticaltabs .nav-pills .nav-link {
  display: block;
  background-color: inherit;
  color: black;
  padding: 13px 16px;
  width: 100%;
  border: none;
  outline: none;
  text-align: left;
  cursor: pointer;
  transition: 0.3s;
  font-size: 14px;
  line-height: 16px;
  border-bottom: 1px solid #d8d8d8;
  margin: 0px 0px;
  border-radius: 0;
}
.verticaltabs h3 {
  font-weight: 500;
  text-align: center;
  font-size: 29px !important;
  margin-bottom: 15px;
}
.verticaltabs p {
  text-align: center;
  line-height: 45px;
  font-size: 18px;
}
.busroute button.accordion-button {
  padding:5px 10px;
  background: #006e27;
  border-radius:2px;
}
.busroute .accordion-body {
  padding: 10px;
}
.busroute button.accordion-button p {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 30px;
  color: #f6df78;
}
.busroute .accordion-button:focus {
  box-shadow: none;
}
.busroute .accordion-button:not(.collapsed)::after, .accordion-button::after {
  filter: invert(1);
} 
.busroute .accordion-item {
  border-radius: 2px;
  margin-bottom: 5px;
}
.chooseyear {
  width: max-content;
  float: right;
  margin-bottom: 30px; 
}
.clr{clear: both;}
.innersec a {
  color: #b29a1c;
  font-size: 14px;
  font-weight: bold;
}
.video-gallery {
  padding: 20px;
  box-shadow: 0px 1px 4px 1px #03030330;
  margin-bottom: 20px;
  background: #fff;
  border-radius: 3px;
}
.video-gallery a {
  display: flex;
  color: inherit;
  align-items: center;
}
.video-gallery a i {
 font-size: 40px;
 color: #ad0807;
 margin-right: 10px;
 display: flex;
}
.video-gallery a h3 {
  font-size: 16px;
  margin-bottom: 0;
  line-height: 24px;
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-top: 0;
  padding-left: 0;
}
.video-gallery p {
  font-size: 12px;
  margin-bottom: 0;
}
.video-gallery a h3:after {
  position: static;
}
.video-gallery a img {
  width: 52px;
  margin-right: 10px;
}
.gallery-inner {
  background: #8fbd61;
  margin-bottom: 30px;
  box-shadow: 7px 6px 12px -4px #ddd;
  /* position: relative; */
  overflow: hidden;
  transition: all 0.5s ease-in;
}
.gallery-blks a {
  color: inherit;
}
.gallery-blks img {
  width: auto;
  max-width: 100%;
  height: 90%;
  margin: auto;
  display: block;
  position: relative;
  box-shadow: 0px 0px 19px 0px #00000054;
  border: 7px solid #ffffff4f;
}
.gallery-title h3::after {
  position: static;
}
.gallery-inner:hover .gallery-content {
  bottom: 0;
}
.gallery-blks {
  height: 230px;
  object-fit: contain;
  position: relative;
  background: #ddd;
  overflow: hidden;
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
} 

.gallery-title p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  line-height: 28px !important;
  font-size: 16px !important;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
}

.gallery-date {
  display: flex;
}

  .gallery-date h6 {
      color: #fff;
      font-size: 16px;
      margin-bottom: 10px;
      line-height: 25px;
      background: #51a242;
      padding: 5px 10px;
      border-radius: 5px;
      font-family: 'Poppins', sans-serif;
      margin-top: 0;
  }

.gall-num h5 {
  background: transparent;
  color: #000;
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 25px;
  padding: 5px 10px;
  border-radius: 5px;
  font-family: 'Poppins', sans-serif;
} 
.innersec .gallery-block a {
  color: inherit;
}
.gallery-content {
  padding: 20px 15px;
  position: relative;
  background: #fff;
  width: 96%;
  margin: -30px auto 10px auto;
}
.gallery-content a.view-more {
  cursor: pointer;
}
.innersec .gallery-block img {
  width: auto;
  max-width: 100%;
  height: 90%;
  margin: auto;
  display: block;
  position: relative;
  box-shadow: 0px 0px 19px 0px #00000054;
  border: 7px solid #ffffff4f;
}

.gallery-inner:hover .gallery-content {
  bottom: 0;
}
.innersec .gallery-block {
  height: 230px;
  object-fit: contain;
  position: relative;
  background: #ddd;
  overflow: hidden;
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}
.sub-gallerycount .innersec .gallery-block img {
  height: 100%;
}
.sub-gallerycount .innersec .gallery-block {
  height: 200px;
  margin-bottom: 20px;
}
.innersec .gallery-block .gallery-title h3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.gallery-title h3 {
  font-size: 18px;
  color: #d0ba43;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  padding: 0px;
  margin-bottom: 15px; 
  -webkit-background-clip: unset;
  -webkit-text-fill-color: unset;
  background: transparent;
}
h4.gallery-title {
  color: #000000bf;
  font-size: 20px;
  line-height: 30px;
  font-family: "Inter", sans-serif;
}
.sub-gallerycount img {
  width: 100%;
  height: 200px;
    object-fit: cover;
  margin-bottom: 20px;
  border: 3px double #ccc;
  padding: 3px;
  background: #4f7add1f;
  border-radius: 3px;
}
.row.tabs-dynamic {
  background:#e1e9df;
  padding: 20px;
  margin: 0px 0px 40px 0px;
}
.row.tabs-dynamic p {
  margin-bottom: 0;
}
.row.tabs-dynamic p a {
  color: #585757;
}
.row.tabs-dynamic select {
  width: 100%;
  border: 0px;
  background: transparent;
  padding: 8px 5px;
  border-bottom: 1px solid #b6b6b6;
}
.row.tabs-dynamic input {
  padding: 9px 5px;
  width: 100%;
  border: 0px;
  border-bottom: 1px solid #b6b6b6;
  background: transparent;
}
.innersec .news-head {display: flex;align-items: center;}
.innersec .news-head h2 { color: #31893b;  padding-right: 20px; border-right: 1px solid #c5c5c5;  margin-right: 20px;  background: #e5e5e5; padding: 4px 10px;}
.innersec .news-head h3 {
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 14px;
  color: #333333d6;
  position: relative;
}
.innersec .news-blk {    background: #fff;margin-bottom: 20px;padding-right: 30px;padding-bottom: 30px;width: 97%;box-shadow: 9px 11px 5px 0px #dddddd3d;position: relative;overflow: hidden;padding: 20px;box-shadow: 0px 0px 12px 0px #e7e7e7;border: 4px double #b5b5b5;transition: 0.5s;}
.innersec .news-blk:before {
  content: "";
  position: absolute;
  top: -40px;
  right: -8px;
  width: 100px;
  height: 100px;
  background: #ecf9ff;
  border-radius: 50%;
}
.innersec .attachment {
  background: #4d9f3d;
  text-align: right;
  position: absolute;
  right: 0;
  bottom: 0;
  color: #fff;
  padding: 5px;
  }
  .innersec .attachment a {
    color: #eeda70;
}
  .news-content {
    min-height: 125px;
}
.innersec .news-content p {margin-bottom: 6px;max-height: 120px;overflow-y: scroll;}
.innersec .news-content p::-webkit-scrollbar {width: 0;}
.innersec .news-blk:hover {
  box-shadow: rgb(0 0 0 / 5%) 0px 2px 1px, rgb(0 0 0 / 5%) 0px 4px 2px, rgb(0 0 0 / 5%) 0px 8px 4px, rgb(0 0 0 / 5%) 0px 16px 8px, rgb(0 0 0 / 5%) 0px 32px 16px;
}
.innersec .news-head h2 span {
  display: block;
  color: #a39756;
  font-size: 18px;
  text-align: center;
}
.newsCount:nth-child(4n+1) > div {
  border-left: 3px solid #fff27e;
}
.newsCount:nth-child(4n+2) > div {
  border-left: 3px solid #ceb2ff;
}
.newsCount:nth-child(4n+3) > div {
  border-left: 3px solid #ff928f;
}
.newsCount:nth-child(4n+4) > div {
  border-left: 3px solid #ffb470;
}
.eventbox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background: #f1f1f1bd;
  box-shadow: 2px 0px 8px 0px #ccc;
  margin-bottom: 20px;
}

.eventboximg {
  width: 40%;
  margin-right: 5px;
  position: relative;
}

.eventboxdesc {
  width: 58%;
}
.eventpgh {
  height: 125px;
  overflow-y: scroll;
  padding-top: 13px;
  padding-right: 2px;
}

.eventboximg img {
  border: 10px solid #f1f1f1bd;
  height: 165px;
  object-fit: contain;
  background: #fff;
  width: 100%;
}
.eventdate {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  padding: 8px 10px 8px 5px;
}
.eventpgh p {
  font-size: 15px;
  line-height: 30px;
}
.eventdate a {
  border-radius: 5px 3px 6px 0px;
  color: #fff;
  padding: 2px 5px;
}
.eventdate span {
  font-size: 13px;
  line-height: 23px;
  color: #1c1b3b;
}
.eventpgh h5 {
  color: #9e790c;
  line-height: 30px;
  font-size: 18px;
  font-family: "Inter", sans-serif;
}
.eventdate span i {
  color: #1c1b3b;
  position: relative;
  bottom: 3px;
}
.eventpgh::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}
 
.eventpgh::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  background-color: #F5F5F5;
} 
.eventattch {
  background:#4d9f3d;
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px 8px 5px 5px;
  border-radius: 13px 0px 13px 0px;
  color: #fff;
}
.eventattch a {
  color: #eeda70;
}
span.bounce1 img { width: 22px; }
.OnlineR a { color: #fff; }
a.parantlogo { padding: 2px 10px; }
.OnlineR { position: fixed; right: 0; z-index: 99; text-align: center; color: #fff; top: 40%; font-family: Open Sans, sans-serif; }
@keyframes bounce1 { 
  0% {
      background-color: #4d9f3d;
  }
  33% {
      background-color:   #7ec171;

  }
  66% {
      background-color: #4d9f3d;
  }
  100% {
      background-color: #7ec171;
  }
}
.bounce1 { border-radius: 25px 0px 0px 25px; color: #fff; font-size: 14px; font-weight: 700; text-align: center; animation: bounce1 1s infinite; padding: 10px 12px; }

.popup {
  padding-right: 0 !important;
}
.popup .carousel-control-prev-icon { background: url('https://webapi.entab.info/api/image/PCSD/public/Images/lefticn.png') no-repeat !important; }
.popup .carousel-control-next-icon { background: url('https://webapi.entab.info/api/image/PCSD/public/Images/righticn.png') no-repeat !important; }
.popup .carousel-control-next, .popup .carousel-control-prev { width: auto; }
.popup .carousel-control-next { right: -15px; }
.popup .modal-dialog { min-width:50%; transform: translateY(-100px); }
.carousel-item .modal-header { background: #4d9f3d; padding: 13px 0 0 }
.popup .modal-dialog img { width: 100%; object-fit: contain;  height: 800px;  background: #f1f1f1;  width: 100%; }
.carousel-item .modal-body h1 {padding-bottom: 0; text-align: center; font-size: 17px; color: #000; overflow: hidden; font-weight: 600; text-transform: capitalize; display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; margin-bottom: 10px; line-height: 28px; }
.carousel-item .modal-dialog .modal-header .btn-close { color: #fff; padding-right: 31px }
.carousel-item .modalcard .card-body { padding: 10px }
.carousel-item .modalcard .card-body p { display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; line-height: 35px; text-align: center; padding-top: 10px; line-height: 26px; }
.carousel-item .modalcard { border: none }
.popup .modal-content { padding: 0 0 10px }
.carousel-item button.btn-close:focus { box-shadow: none }
.carousel-item a.btn.btn-primary.btn-sm {background: #4d9f3d; border: #4d9f3d; margin: 0 10px; color: #fff!important ;}
.carousel-item a.btn.btn-primary.btn-sm:focus { box-shadow: none; border: 1px solid #4d9f3d; }
.carousel-item button.btn-close {position: absolute;z-index: 999;background-color: #4d9f3d;right: 0;}
.carousel-item .btn-close { background: url('https://webapi.entab.info/api/image/PCSD/public/Images/closeicn.png') no-repeat; opacity: 1; padding: 10px; background-position: center; border-radius: 0; }
.modal-bottom { display: flex; flex-direction: row; justify-content: center; align-items: center; margin-top: 30px; }
 
.Magazine{margin-bottom: 15px;}
.Magazine img {width: 130px;height: 150px;margin: 10px auto; display: block;}
.Magazine p{text-align:center;font-size: 18px;color: #000000;}
.erp_sec .guideheading {
  text-align: left;
}
#myImg, #myImg1, #myImg2 {
  width: 100%;
  border: 5px solid rgba(158, 158, 158, 0.1);
  background: #eaeaea;
  cursor: pointer;
  transition: 0.3s;
}
.erp_sec img { 
  display: inline-block;
}
.erp_sec h4.imgheading {
  text-align: center;
}
.assistance {
  background: #eaeeff;
  padding: 20px;
  margin-top: 30px;
}
.erp_sec h4 {
  text-align: center;
  line-height: 40px;
  font-size: 17px;
  margin-bottom: 0;
  margin-top: 15px;
  font-weight: 700;
  font-family: 'Roboto',sans-serif;
}

.assistance {
  background:rgb(230 243 255);
  padding: 13px;
  border: solid 1px #ddd;
  margin-top: 20px;
}
.erpbox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.erpboxdesc {
  width: 72%;
}

.erpboximg {
  width: 26%;
}

.erpboxdescpopup {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.erppopup {
  width: 30%;
  margin: 10px;
}

.imgheading a { 
  text-align: center;
  width: 100%;
  display: block;
  font-size: 20px;
  padding: 10px 0;
}

.erppopup .modal-body h5 {
  color: #000;
  text-align: center;
  padding-top: 20px;
}
 
.modal {
  background: #0000008a;
  z-index: 99999;
  padding-right: 0 !important;
}
h3.Subhead {
  color: #104677;
  font-size: 20px;
}

.magnify-modal {
  position: fixed !important;
  z-index: 999999999 !important;
  padding-top: 10px !important;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  overflow: auto !important;
  background-color: rgba(0, 0, 0, 0.8509803922) !important
}

.magnify-header .magnify-toolbar {
  float: right;
  position: relative;
  z-index: 9
}

.magnify-stage {
  position: absolute;
  top: 40px;
  right: 10px;
  bottom: 40px;
  left: 10px;
  z-index: 1;
  background: #0000000a;
  overflow: hidden
}

.magnify-image {
  position: relative;
  display: inline-block;
  border: 5px solid #fff
}

.magnify-image.image-ready {
  max-width: 100%;
  max-height: 100%
}

.magnify-footer {
  position: absolute;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 40px;
  color: #fff;
  text-align: center
}

.magnify-footer .magnify-toolbar {
  display: inline-block
}

.magnify-button {
  display: inline-block;
  width: 40px;
  height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  border-width: 0;
  border-radius: 0;
  cursor: pointer;
  outline: none;
  background: #055a91;
}

.magnify-button:hover {
  color: #fff;
  background-color: #e00000
}

.magnify-button-close:hover {
  background-color: #e00000
}

.magnify-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.3);
  color: #333
}

.magnify-loader::before {
  content: '';
  display: inline-block;
  position: relative;
  width: 36px;
  height: 36px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  -webkit-animation: magnifyLoading 1s infinite linear;
  animation: magnifyLoading 1s infinite linear
}

.magnify-loader::after {
  content: '';
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  overflow: hidden
}

@-webkit-keyframes magnifyLoading {
  0% {
      -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
      transform: rotateZ(0deg) translate3d(0, 0, 0)
  }

  100% {
      -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
      transform: rotateZ(360deg) translate3d(0, 0, 0)
  }
}

@keyframes magnifyLoading {
  0% {
      -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
      transform: rotateZ(0deg) translate3d(0, 0, 0)
  }

  100% {
      -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
      transform: rotateZ(360deg) translate3d(0, 0, 0)
  }
}
p.name span {
  display: block;
  font-weight: bold;
  color: #b29a1c;font-size: 12px;
}

p.name {color: #333;font-weight: bold;}
.msgimg{
  border: 3px double #ccc;
  padding: 5px;
  border-radius: 7px;
  margin-left: 20px;
}
    #chooseddlYear option { color: black;}
    .chooseyear  select{margin:0px;}
    .chooseyear  select{width:100%;background: unset;border: unset;height:28px; margin-top:15px;border: unset;border-bottom: 1px solid grey;}
    .navbar-toggler:focus { 
      box-shadow: none;
  }
  ul.sitemapli {
    padding-left: 20px;
}

ul.sitemapli li a {
    color: #333;
}
h3.subheading {
    font-size: 20px;
    color: #75af57;
    line-height: 30px;
}
span.fran {
  display: block;
  font-size: 12px;
  color: #fff;
  margin-top: 10px;
}
.principalsman .management-detils { 
  min-height: 100px;
}
@media  (max-width:1700px) {
  .shape , .topheaderdesc{
    width: 520px;
}
}
@media  (max-width:1666px) {
.nav-link {
  padding: 10px 10px;
  font-family: 'Futura Md BT';
  font-size: 16px;
  font-weight: normal;
  color: #fff;
  margin-right: 5px;
  margin-left: 5px;
}
.header-nav {
  width: 72%;
}
}
@media  (max-width:1600px) {
  .highlights {
    width: 38%;
  }
  .topheaderleftbg { 
    left: -40px;
}
  .birthdaysec {
    padding: 50px 10px;
  }
  .midsectiondiv {
    width: 32%;
  }
}

@media (max-width: 1400px){
 
  .midsectionboxtitle {
    padding: 0px 10px;
}
  .midsectiondiv.scd .midsectionboxpara {
    padding-left: 10px;
}
  .midsectionboxpara li {
    font-size: 14px;
  }
  .midsectiondiv.fst {
    padding: 50px 15px 50px 0px;
  }
  .midsectiondiv.fst p { 
    font-size: 14px;
  }
  .topheaderleftbg { 
    left: -120px;
}
  .toppersecbg::before {
    left: 0;
  }
  .toppersecbg::after {
    right: 0;
  }
.header-nav {
    width: 85%;
}
.fulwidth {
  width: calc(99% - 100px);
}
}
 
@media (max-width: 1300px){
  .quick-link li a { 
    font-size: 15px;
}
.cont-dtl p {
  font-size: 15px;}
  .header-nav {
    width: 85%;
}
.headermid {
  margin: 10px auto 10px auto;
}
}
@media (max-width: 1200px){
  .nav-link { 
    font-size: 15px;
  }
  .footer{background:none;}
  .footerlogo {
    display: block;
    text-align: center;
    margin: 0 auto 40px auto;
}
  .quick-link ul {
    width: 100%;
  }
  .fulwidth {
    width: calc(100% - 100px);
}
  .header-nav {
      width: 100%;
  }
  .homeslider { 
    margin-top: 0;
}
.midsection { 
  position: static;
}
.highlights {
  width: 100%;
}
.midsectiondiv {
  width: 49%;
}
.midsectiondiv.fst ,.midsectiondiv.scd{
  margin-top: 10px;

}
.newsbg {
  padding: 50px 20px;
}
  }

  @media (max-width: 992px){
    .rightimg,.leftimg {
      float: none; 
      margin: 0 auto 20px auto;
      display: block;
  }
    .uniformimg { 
      width: 35%;
    }

 
    .toppersecbg {
      margin-left: 0;
    }
  .navbar-expand-lg .navbar-nav {
    flex-direction: column; 
    align-items: flex-start; 
    margin-left: 0;
    width: 100%;
} 
.navbar-expand-lg  { 
  justify-content: flex-end; 
} 
button.navbar-toggler {
  background: #fff;
}
  }

  @media (max-width: 768px){
    .uniformimg { 
      width: 100%;
      float: none;
      margin-left: 0;
      margin-bottom: 10px;
    }

    .highlight-blk {
      justify-content: center;
    }
    .shape, .topheaderdesc {
      width: 80%;
  }
    .topheaderleftbg {
      left: 0;
  }
    .topheaderleft {
      width: 100%;
  }
    .highlight-tab {
      width: 100%;
  }
    .headertop {
      flex-wrap: wrap;
    }
    .topperbtn {
      justify-content: end;
  }
    .midsectiondiv {
      width: 100%;
  }
  .toppersecbg {
    margin-left: 0;
  }
}